import React, { useState } from "react"

import menuImage from '../images/menu.svg';

import styled from "styled-components";
import { goToDiv } from "../utils"

const Container = styled.header`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3%;
`;

const MenuIcon = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const Sidebar = styled.div`
  position: fixed;
  right: ${props => props.toggle ? '0' : '-290px'};
  top: 0;
  bottom: 0;
  height: 100vh;
  padding: 100px;
  z-index: 1;
  background-color: #000000;
  color: #ffffff;
  transition: .5s;
`;

const SidebarHeader = styled.div``;

const CloseIcon = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SidebarItem = styled.span`
  margin: 20px 0;
  cursor: pointer;
  transition: .3s;
  
  &:hover {
    color: #71B1C5;
  }
`;

const Header = () => {
  const [toggle, setToggle] = useState(false);

  const handleNavigation = id => {
    goToDiv(id);
    return setToggle(false);
  }

  return (
    <Container>
      <MenuIcon src={menuImage} alt="menu-icon" onClick={() => setToggle(true)} />
      <Sidebar toggle={toggle}>
        <SidebarHeader>
          <CloseIcon src={require('../images/close.svg')} alt="close-icon" onClick={() => setToggle(false)} />
        </SidebarHeader>
        <SidebarContent>
          <SidebarItem onClick={() => handleNavigation('about')}>Něco o nás</SidebarItem>
          <SidebarItem onClick={() => handleNavigation('gallery')}>Galerie</SidebarItem>
          <SidebarItem onClick={() => handleNavigation('contact')}>Kontakt</SidebarItem>
        </SidebarContent>
      </Sidebar>
    </Container>
  )
}

export default Header
