export const group1 = [
  {
    src: require('../images/work/work1.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work2.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work3.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work4.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work5.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work6.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work7.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work8.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work9.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work10.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work11.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work12.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work13.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work14.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work15.jpg'),
    width: 1,
    height: 1
  }
];

export const group2 = [
  {
    src: require('../images/work/work16.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work17.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work18.jpg'),
    width: 1,
    height: 1
  }
];

export const group3 = [
  {
    src: require('../images/work/work19.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work20.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work21.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work22.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work23.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work24.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work25.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work26.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work27.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work28.jpg'),
    width: 1,
    height: 1
  },
];

export const group4 = [
  {
    src: require('../images/work/work29.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work30.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work31.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work32.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work33.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work34.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work35.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work36.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work37.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work38.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work39.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work40.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work41.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work42.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work43.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work44.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work45.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work46.jpg'),
    width: 1,
    height: 1
  }
];

export const group5 = [
  {
    src: require('../images/work/work47.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work48.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work49.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work50.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work51.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work52.jpg'),
    width: 1,
    height: 1
  }
]

export const group6 = [
  {
    src: require('../images/work/work53.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work54.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work55.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work56.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work57.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work58.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work59.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work60.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work61.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work62.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work63.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work64.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work65.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work66.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work67.jpg'),
    width: 1,
    height: 1
  },
  {
    src: require('../images/work/work68.jpg'),
    width: 1,
    height: 1
  }
]
