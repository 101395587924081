import { goToDiv } from "../utils"
import React from "react"
import Header from "./header";
import Logo from "./logo"

import Button from "./button";
import backgroundImage from "../images/background.jpg";
import styled from "styled-components"

const Container = styled.div`
  position: relative;
  background: linear-gradient(rgb(255, 255, 255, 1), rgb(255, 255, 255, .1)), url('${backgroundImage}');
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .75);
`;

const Title = styled.h1`
  font-weight: 500;
`;

const SubTitle = styled.h3`
  font-weight: 300;
`;

const MoreButton = styled(Button)`
  margin-top: 20px;
`;

const Landing = () => (
  <Container>
    <Header />
    <Logo width={350} />
    <Title>VÍTEJTE</Title>
    <SubTitle>NA NAŠI PREZENTACI WEBU</SubTitle>
    <MoreButton label="Další informace" onClick={() => goToDiv('about')} />
  </Container>
)

export default Landing
