import React from "react"
import './normalize.css';

import SEO from "./seo"
import Landing from "./landing";
import About from "./About";
import OurWork from "./ourWork";
import Contact from "./contact";

import styled from "styled-components";

const Container = styled.div``;

const Content = styled.div``;

const Layout = () => {
  return (
    <Container>
      <SEO title="VÍTEJTE" />
      <Content>
        <Landing />
        <About />
        <OurWork />
        <Contact />
      </Content>
    </Container>
  )
}

export default Layout
