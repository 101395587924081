import axios from "axios";
import React, { useState } from "react";
import Logo from "./logo";
import Button from "./button";

import facebookIcon from "../images/facebook.svg";
import backgroundImage from "../images/contact.jpg";
import locationIcon from "../images/location.svg";
import mailIcon from "../images/mail.svg";
import phoneIcon from "../images/phone.svg";

import { API_URL, hasError, isEmpty } from "../utils"

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Form = styled.div`
  background: url('${backgroundImage}') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10%;
  position: relative;
`;

const FormInput = styled.input`
  width: 300px;
  margin: 20px;
  border: none;
  height: 30px;
  border-radius: 5px;
  box-shadow: ${props => props.error ? `0 5px 15px 0 rgba(255, 0, 0, .75)` : `0 5px 15px 0 rgba(0, 0, 0, .75)`};
  outline: none;
  padding: 5px 10px;
  ${props => props.error && `border: 1px solid red; &::placeholder { color: red; }`}
`;

const FormMessage = styled.textarea`
  width: 300px;
  margin: 20px;
  border: none;
  height: 110px;
  border-radius: 5px;
  box-shadow: ${props => props.error ? `0 5px 15px 0 rgba(255, 0, 0, .75)` : `0 5px 15px 0 rgba(0, 0, 0, .75)`};
  outline: none;
  padding: 10px;
  resize: none;
  ${props => props.error && `border: 1px solid red; &::placeholder { color: red; }`}
`;

const SendButton = styled(Button)`
  margin-top: 20px;
`;

const ContactDetails = styled.div`
  background-color: #dcdcdc;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
`;

const ContactIcon = styled.img`
  width: 25px;
`;

const ContactLabel = styled.span`
  margin: 10px;
`;

const Footer = styled.div`
  background-color: #000000;
  padding: 15px;
`;

const FooterItem = styled.span`
  font-size: 12px;
  margin-left: 30px;
  color: #ffffff;
`;

const FacebookIcon = styled.img`
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 3%;
`;

const SuccessMessage = styled.div`
  cursor: pointer;
  background-color: #5CB85C;
  margin-top: 50px;
  padding: 10px 50px;
  border-radius: 5px;
  color: #ffffff;
  transition: .3s;
  
  &:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .75);
  }
`;

const INIT_STATE = {
  name: '',
  email: '',
  message: ''
}

const Contact = () => {
  const [data, setData] = useState(INIT_STATE);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSend = async () => {
    setLoading(true);
    const error = hasError(data);
    if (!isEmpty(error)) {
      setLoading(false);
      setErrors(error);
      return;
    }

    try {
      await axios.post(API_URL, { ...data });
      setShowSuccess(true);
      setErrors({});
      setLoading(false);
    } catch (e) {
      console.error(e);
      setErrors({});
      setLoading(false);
    }
    setData(INIT_STATE);
  }

  const handleOpenFacebookPage = () =>
    window.open('https://www.facebook.com/izolacevladimir/', '_blank');

  return (
    <Container id="contact">
      <Form>
        <FacebookIcon src={facebookIcon} alt="facebook-icon" onClick={handleOpenFacebookPage} />
        <FormInput
          value={data.name}
          placeholder="Jméno"
          type="text"
          onChange={e => setData({ ...data, name: e.target.value })}
          error={errors.name}
        />
        <FormInput
          value={data.email}
          placeholder="Email"
          type="text"
          onChange={e => setData({ ...data, email: e.target.value })}
          error={errors.email}
        />
        <FormMessage
          value={data.message}
          placeholder="Zpráva"
          onChange={e => setData({ ...data, message: e.target.value })}
          error={errors.message}
        />
        <SendButton label={loading ? 'Čekejte...' : 'Odeslat'} onClick={loading ? () => {} : handleSend} />
        {showSuccess && <SuccessMessage onClick={() => setShowSuccess(false)}>Zpráva byla odeslána, děkuji! &#128522;</SuccessMessage>}
      </Form>
      <ContactDetails>
        <Logo />
        <ContactItem>
          <ContactIcon src={locationIcon} alt="location-icon" />
          <ContactLabel>Katovicka 409/8 <br/> Praha 8, 18100 Bohnice</ContactLabel>
        </ContactItem>
        <ContactItem>
          <ContactIcon src={mailIcon} alt="mail-icon" />
          <ContactLabel>mv-izol@seznam.cz</ContactLabel>
        </ContactItem>
        <ContactItem>
          <ContactIcon src={phoneIcon} alt="phone-icon" />
          <ContactLabel>+420 727 820 561</ContactLabel>
        </ContactItem>
      </ContactDetails>
      <Footer>
        <FooterItem>© Powered by MV - izol</FooterItem>
      </Footer>
    </Container>
  )
}

export default Contact;
