import React, { useCallback, useState } from "react"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images";

import { group1, group2, group3, group4, group5, group6 } from "./photos"

import styled from "styled-components"

const Container = styled.div`
  padding: 5%;
  
  .react-photo-gallery--gallery > div {
    justify-content: center;
    align-items: center;
  }
  
  img {
    width: 100px !important;
    height: 100px !important;
    margin: 10px !important;
    transition: .3s;
  }
  
  img:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .75);
  }
`;

const Description = styled.h3`
  text-align: center;
  font-weight: 400;
`;


const OurWork = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const photos = [...group1, ...group2, ...group3, ...group4, ...group5, ...group6];

  const openLightbox = useCallback((event, { photo }) => {
    const index = photos.findIndex(p => p.src === photo.src);
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Container id="gallery">
      <Gallery photos={group1} onClick={openLightbox} />
      <Description>Sanace na balkónech v Bohdalci pomoci materijalu Triflex</Description>
      <hr/>
      <Gallery photos={group2} onClick={openLightbox} />
      <Description>Postup práce na rovné střeše ve Vršovicích: <br/> Očištěný beton jsme nejprve napenetrovali a následně byla použita  parotěsná zábrana z Glastek Al 40 mineral. Jako Zateplovací vrstvu jsme zvolili dvě vrstvy polystyrenu, první vrstva rovné tabule v tloušťce 10cm a druhá v druhé vrstvě spádové klíny s 2 % spádem a vše překryto bílou geotextilií. Jako finální povrch byla použita fólie Fatrafol 810. Následně byla provedena zatopová zkouška , která vyšla bez jediné chyby a dokázala kvalitu naší práce.</Description>
      <hr/>
      <Gallery photos={group3} onClick={openLightbox} />
      <Description>Proběhlo zahájení prací na terase v Holešovicích. Jako materiál používána PVC fólie podložena textilií a vše následně ukotveno. Majitel naprosto spokojen a jak se to líbí vám? Ozvěte se, rád vám pomůžu.</Description>
      <hr/>
      <Gallery photos={group4} onClick={openLightbox} />
      <Description>Máme za sebou další pracovní týden ,tak jsme si našli čas abychom přidali další fotky. Provádění prací probíhalo na rodinném domu, jako materiál jsme používali asfaltove pásy Glastek 40 Speciál Mineral. Majitelé jsou spokojení jako vždy a přesně kvůli takové spokojenosti našich zákazníků máme svoji práci rádi.</Description>
      <hr/>
      <Gallery photos={group5} onClick={openLightbox} />
      <Description>Práce na základové desce v obci Libkovice pod Řípem. Použitý materiál Glastek 40 speciál minerál proti radonu.</Description>
      <hr/>
      <Gallery photos={group6} onClick={openLightbox} />
      <Description>I když nám počasí moc nepřeje, svou práci musíme dělat stále perfektně, jako v létě. Teď máme za sebou další dokončenou práci v Dobříši. Základem byla parozábrana, na kterou jsme položili 2 vrstvy rovného polystyrénu v tloušťce 10 cm a na ně spádové klíny. Pro zpevnění jsme vše přitížili překližkou, na kterou jsme položili geotextilii a PVC fólii DEKPLAN 77, která je vhodná k přitížení.</Description>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Container>
  )
}

export default OurWork;
