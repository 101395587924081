import React from "react"

import styled from "styled-components";

const Component = styled.div`
  background-color: transparent;
  padding: 10px 30px;
  border-radius: 5px;
  border: 3px solid #000;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: .3s;
  
  &:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .75);
  }
`;


const Button = ({ label, onClick, ...other }) => (
    <Component {...other} onClick={onClick}>{label}</Component>
)

export default Button
