import React from "react"

import styled from "styled-components"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 5%;
`;

const Title = styled.h1`
  font-weight: 500;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  min-width: 300px;
`;

const Image = styled.img`
  width: 35%;
  min-width: 300px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .75);
`;

const Description = styled.p`
  text-align: justify;
  font-size: 18px;
`;

const About = () => (
  <Container id="about">
    <Image src={require("../images/about.jpg")} />
    <Content>
      <Title>Něco o nás:</Title>
      <Description>
        Firma <strong>MV-izol</strong> je na trhu sice jen krátkou dobu, ale náš tým se může pyšnit více než desetiletou praxí. V oboru hydroizolací jsme profesionálové již od roku 2008 a dosud jsme dokončili spoustu úspěšných zakázek. Na prvním místě je pro nás spokojenost zákazníka a kvalita odvedené práce. Neméně důležité je vyřízení zakázky ve sjednané termínu. V případě zájmu se budeme těšit na spolupráci.
      </Description>
    </Content>
  </Container>
)

export default About;
