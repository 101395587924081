import React from "react"

import logoImage from "../images/logo.png"

import styled from "styled-components"

const Component = styled.img`
  width: ${props => `${props.width}px;`};
`;

const Logo = ({ width = 200 }) => (
  <Component width={width} src={logoImage} alt="mv-izol-logo" />
)

export default Logo;