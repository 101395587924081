export const goToDiv = id => {
  document.getElementById(id).scrollIntoView();
}

export const isEmpty = obj => {
  for(let prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

const validateEmail = email => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const hasError = data => {
  const error = {};

  if (!data.name) error.name = true;
  if (!data.email || !validateEmail(data.email)) error.email = true;
  if (!data.message) error.message = true;

  return error;
}

export const API_URL = 'https://getform.io/f/d56b1ae0-ce82-449a-b5ff-c04b281309f6';